import React from 'react'
import {
  Button,
  Pressable,
  Modal,
  Toggle,
  createFormatterFn,
} from '@quotefactory/ui'
import {useDispatch} from 'react-redux'
import {updateAccount} from 'reducers/account'
import {useFeatures} from 'hooks'

export const formatAsUSDsansDecimals = createFormatterFn({
  locale: 'en-US',
  currency: 'USD',
  decimalPlaces: 0,
})

export default function AccountFeatureFlags({account, disabled}) {
  const accountId = account.id
  const [openModal, setOpenModal] = React.useState(null)
  const closeModal = React.useCallback(() => {
    setOpenModal(null)
  }, [setOpenModal])

  const dispatch = useDispatch()

  const {features} = account

  const allFeatures = useFeatures()
  const acctFeatures = new Set(features)

  const handleToggleFeatures = (featureName) => {
    if (!acctFeatures.has(featureName)) {
      acctFeatures.add(featureName)
    } else {
      acctFeatures.delete(featureName)
    }
    dispatch(updateAccount({accountId, features: [...acctFeatures]}))
  }

  return (
    <>
      <Pressable
        className="bg-transparent rounded-lg p-3 hover:shadow-[0_0_0_2px] hover:shadow-blue-500 transition duration-300 flex flex-col items-stretch !-mt-3"
        onClick={() => {
          setOpenModal('customer-settings')
        }}>
        <div className="text-13">
          <div className="flex items-center justify-between border-b border-gray-300 pb-2 mb-3">
            <div className="text-black-100 text-12 up font-semibold flex items-center space-x-1">
              <span>Feature Flags</span>
            </div>
          </div>
          <div>
            {allFeatures.map((featureName) => {
              return (
                <div key={featureName} className="py-1 flex items-center gap-2">
                  <div
                    className={`rounded-full w-3 h-3 border ${
                      acctFeatures.has(featureName)
                        ? 'bg-green-500 border-green-500'
                        : 'bg-gray-200 border-gray-500'
                    }`}
                  />
                  <div
                    className={
                      !acctFeatures.has(featureName) ? 'text-gray-700' : ''
                    }>
                    {featureName}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </Pressable>

      <Modal
        isOpen={openModal === 'customer-settings'}
        onClose={closeModal}
        shouldTriggerOnCloseWithOutsideClick>
        <Modal.Content className="lg:w-[400px] overflow-auto bg-white">
          <div className="flex flex-col items-stretch p-8 pb-10">
            <div className="text-17 font-bold pb-3">Feature flags</div>
            <div className="flex flex-col gap-4">
              {allFeatures
                .filter((featureName) => featureName !== 'itemized-charges')
                .map((featureName) => {
                  const elName = `feature-${featureName}`
                  return (
                    <div
                      key={featureName}
                      className="flex items-center justify-between">
                      <div>{featureName}</div>
                      <Toggle
                        id={elName}
                        name={elName}
                        checked={acctFeatures.has(featureName)}
                        onChange={() => {
                          handleToggleFeatures(featureName)
                        }}
                        disabled={disabled}
                      />
                    </div>
                  )
                })}
            </div>
            <div className="pt-8">
              <Button className="btn blue w-full !text-17" onClick={closeModal}>
                Done
              </Button>
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </>
  )
}
