import * as React from 'react'
import {SVG} from '@quotefactory/ui'
import {useSelector, useDispatch} from 'react-redux'
import {fetchAccount, selectAccountById} from 'reducers/account'
import {AccountIntegrationListItem} from 'components/account'

function SortIndicator({sort, sortKey}) {
  if (sort.key !== sortKey) {
    return null
  }
  return (
    <span>
      {sort.asc && <SVG name="arrowAsc" className="asc" />}
      {!sort.asc && <SVG name="arrowDesc" className="desc" />}
    </span>
  )
}

export default function AccountIntegrationList({accountId}) {
  const dispatch = useDispatch()
  const account = useSelector((state) => selectAccountById(state, accountId))
  const integrations = account?.integrations || []
  const [sort, setSort] = React.useState({key: 'name', asc: true})

  const fetchData = async () => {
    await dispatch(fetchAccount(accountId))
  }

  React.useEffect(() => {
    fetchData()
  }, [])

  const flatEntities = React.useMemo(() => {
    const data = []

    for (const integration of integrations) {
      data.push({
        ...integration,
        accountName: account.name,
        accountId: account.id,
      })
    }

    return data
  }, [integrations])

  const sortedEntities = React.useMemo(() => {
    const {key, asc} = sort
    let sortedList
    if (key === 'name') {
      sortedList = flatEntities.sort((a, b) => {
        const ret = a.accountName.localeCompare(b.accountName)
        if (ret !== 0) {
          return ret
        }
        if (a.createdAt < b.createdAt) return 1
        if (a.createdAt > b.createdAt) return -1
        return 0
      })
    } else {
      sortedList = flatEntities.sort((a, b) =>
        (a[key] || '').localeCompare(b[key]),
      )
    }
    if (!asc) {
      sortedList.reverse()
    }
    return sortedList
  }, [flatEntities, sort])

  const sortList = (key) => {
    let {asc} = sort
    if (key === sort.key) {
      asc = !asc
    }
    setSort({key, asc})
  }

  const sortClass = (sortKey) => {
    return `sort uppercase text-xs ${sort.key === sortKey ? 'sorted' : ''}`
  }

  return (
    <div className="table-cards">
      <div className="header flex px-6 py-2 text-sm font-semibold sticky top-0 text-black-100 mb-3 !bg-transparent">
        <div className="w-4">
          <button
            type="button"
            className={sortClass('active')}
            onClick={() => sortList('active')}>
            •
            <SortIndicator sort={sort} sortKey="active" />
          </button>
        </div>
        <div className="w-1/5">
          <button
            type="button"
            className={sortClass('name')}
            onClick={() => sortList('name')}>
            Integration
            <SortIndicator sort={sort} sortKey="name" />
          </button>
        </div>
        <div className="w-1/6">
          <button
            type="button"
            className={sortClass('createdAt')}
            onClick={() => sortList('createdAt')}>
            Request Date
            <SortIndicator sort={sort} sortKey="createdAt" />
          </button>
        </div>
        <div className="w-1/6">
          <button
            type="button"
            className={sortClass('status')}
            onClick={() => sortList('status')}>
            Status
            <SortIndicator sort={sort} sortKey="status" />
          </button>
        </div>

        <div className="flex-1 text-right">
          <button
            type="button"
            className={sortClass('integrationType')}
            onClick={() => sortList('integrationType')}>
            Type
            <SortIndicator sort={sort} sortKey="integrationType" />
          </button>
        </div>
      </div>

      {sortedEntities.map((item) => (
        <AccountIntegrationListItem
          key={item.id}
          account={item}
          refreshData={fetchData}
        />
      ))}

      {integrations.length < 1 && (
        <div className="py-4 px-6 text-sm text-black-300">
          No pending account integrations yet
        </div>
      )}
    </div>
  )
}
