import React, {useState, useEffect} from 'react'
import {AccountUser, AccountInvitee} from 'components/account'
import api from 'services/api'

export default function AccountUsers({accountId}) {
  const [team, setTeam] = useState({})

  const fetchTeam = async () => {
    const result = await api.fetchAccountUsers(accountId)
    setTeam(result)
  }
  useEffect(() => {
    fetchTeam()
  }, [])

  if (!team?.users) return null

  const primaryUser = team.users.find((u) => u.isPrimary)
  const users = [
    primaryUser,
    ...team.users.filter((u) => u.id !== primaryUser.id),
  ].filter(Boolean)

  return (
    <>
      <ul className="py-3 flex flex-col gap-3">
        {users &&
          users.map((member) => (
            <li key={member.id}>
              <AccountUser
                accountId={accountId}
                member={member}
                fetchTeam={fetchTeam}
              />
            </li>
          ))}

        {team?.invites &&
          team?.invites.map((invite) => (
            <li key={invite.id}>
              <AccountInvitee invite={invite} />
            </li>
          ))}
      </ul>
    </>
  )
}
